import { api } from './configs/axiosConfig';

const endPoint = "/dashboard";

export const dashboardAPI = {
    getDahboardData: async function (sectionCode,pcode) {
        const reponse = await api.request({
            url: `${endPoint}`,
            method: 'GET',
            params: { sectionCode, pcode }
        });

        return reponse.data;
    }
}