export const common = {
    convertToDate: function (dateTime) {
        let date = new Date(dateTime);

        return `${date.getFullYear()}/${date.getMonth()+1}/${date.getDay()}`;
    },
    convertToDateTime: function (dateTime) {
        let date = new Date(dateTime);

        return `${date.getFullYear()}/${date.getMonth()+1}/${date.getDay()} ${date.getHours()}:${date.getMinutes()}`;
    },
    convertToMonthDay: function (dateTime) {
        let date = new Date(dateTime);
        
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        return `${month[date.getMonth()]}/${date.getDate()}`;
    },
    convertToTime: function (dateTime) {
        let date = new Date(dateTime);

        return `${date.getHours()}:${date.getMinutes()}`;
    },
}