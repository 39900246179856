import { LiaRedoAltSolid } from "react-icons/lia";
import { Routes, Route, Link } from 'react-router-dom';

import './home.css';
export default function Home() {

    const onClickHom = () => {
        window.location.reload(); 
    }

    return (
        <button type="button" className="border border-0 d-flex align-items-center fs-3 home" onClick={() => onClickHom()}>< LiaRedoAltSolid /></button>
    );
}