import Home from '../../components/home';
import { useEffect, useState, useRef } from "react";
import { toast } from 'react-toastify';

import Spinner from "../../components/spinner/spinner";

import { dashboardAPI } from "../../api/dashboardAPI";
import { sectionAPI } from "../../api/section";

import { useLocation } from "react-router-dom";

import { common } from '../../common/common';

import './mainMenu.css';

import $ from 'jquery'

export default function MainMenu({ logOut }) {
    const didMount = useRef(true);
    const errorCount = useRef(0); // refresh the page if api error occurs continuosly for 5 times

    const myParam = useLocation().search;
    const [sectionCode, setSectionCode] = useState(new URLSearchParams(myParam).get("sectioncode"));
    const [pcode, setPCode] = useState(new URLSearchParams(myParam).get("pcode"));
    const [sectionName, setSectionName] = useState('');

    const [pending, setPending] = useState([]);
    const [processing, setProcessing] = useState([]);
    const [completed, setCompleted] = useState([]);

    const [pendingUrgentTot, setPendingUrgentTot] = useState(0);
    const [pendingRejectedTot, setPendingRejectedTot] = useState(0);
    const [pendingTotOverOneDay, setPendingTotOverOneDay] = useState(0);
    const [pendingTotOverTwoDay, setPendingTotOverTwoDay] = useState(0);
    const [pendingTotOverThreeDay, setPendingTotOverThreeDay] = useState(0);

    const [processingUrgentTot, setProcessingUrgentTot] = useState(0);
    const [processingCorrectionTot, setProcessingCorrectionTot] = useState(0);
    const [processingRejectedTot, setProcessingRejectedTot] = useState(0);
    const [processingTotOverOneDay, setProcessingTotOverOneDay] = useState(0);
    const [processingTotOverTwoDay, setProcessingTotOverTwoDay] = useState(0);
    const [processingTotOverThreeDay, setProcessingTotOverThreeDay] = useState(0);

    const [completedUrgentTot, setCompletedUrgentTot] = useState(0);
    const [completedRejectedTot, setCompletedRejectedTot] = useState(0);

    useEffect(() => {
        if (didMount.current === true) {
            didMount.current = false;

            if (sectionCode === null || sectionCode.trim().length === 0) {
                toast.warn("Section code not found");
                return;
            }

            sectionAPI.getSection(sectionCode, pcode).then((data) => {
                setSectionName(data);
                getDahboardData(sectionCode, pcode);
                setIntervalDahboardData(sectionCode, pcode);
            });
        }
    }, [])

    const Header = () => {
        return (
            <div>
                <Home />
            </div>
        );
    }

    const checkErrorCount = () => {
        if (errorCount.current >= 5) { // refresh the page if api error occurs continuosly for 5 times
            window.location.reload();
        }
    }

    const setIntervalDahboardData = (sectionCode, pcode) => {
        setInterval(function () {
            getDahboardData(sectionCode, pcode);
        }, 16000);
    }

    const getDahboardData = (sectionCode, pcode) => {
        dashboardAPI.getDahboardData(sectionCode, pcode).then((data) => {

            setPendingUrgentTot(data.pendingUrgentTot);
            setPendingRejectedTot(data.pendingRejectedTot);
            setPendingTotOverOneDay(data.pendingTotOverOneDay);
            setPendingTotOverTwoDay(data.pendingTotOverTwoDay);
            setPendingTotOverThreeDay(data.pendingTotOverThreeDay);

            setProcessingUrgentTot(data.processingUrgentTot);
            setProcessingCorrectionTot(data.processingCorrectionTot);
            setProcessingRejectedTot(data.processingRejectedTot);
            setProcessingTotOverOneDay(data.processingTotOverOneDay);
            setProcessingTotOverTwoDay(data.processingTotOverTwoDay);
            setProcessingTotOverThreeDay(data.processingTotOverThreeDay);

            setCompletedUrgentTot(data.completedUrgentTot);
            setCompletedRejectedTot(data.completedRejectedTot);

            setPending(data.pending);
            setProcessing(data.processing);
            setCompleted(data.completed);
            errorCount.current = 0;
        }).
            catch(() => {
                errorCount.current = errorCount.current + 1;
                checkErrorCount();
            });
    }


    if (pending.length !== 0) {
        setTimeout(() => {
            $('#jobInfoPending').animate(
                { scrollTop: $("#jobInfoPending").height() + 1000 + 'px' },
                12000,
                function () {
                    $('#jobInfoPending').scrollTop(0);
                }
            )
        }, 3000);
    }

    if (processing.length !== 0) {
        setTimeout(() => {
            $('#jobInfoProcessing').animate(
                { scrollTop: $("#jobInfoProcessing").height() + 1000 + 'px' },
                12000,
                function () {
                    $('#jobInfoProcessing').scrollTop(0);
                }
            )
        }, 3000);
    }

    if (completed.length !== 0) {
        setTimeout(() => {
            $('#jobInfoCompleted').animate(
                { scrollTop: $("#jobInfoCompleted").height() + 1000 + 'px' },
                12000,
                function () {
                    $('#jobInfoCompleted').scrollTop(0);
                }
            )
        }, 3000);
    }

    // Old method for auto scrolling
    //const scrollSpeed = 4; 
    //const delayTime = 5000; 
    //let scrollingUp = true;

    //function autoScrollPending() {
    //    const scrollingDiv = document.getElementById('jobInfoPending');
    //    const currentScroll = scrollingDiv.scrollTop;

    //    if (scrollingUp) {
    //        scrollingDiv.scrollTo({
    //            top: currentScroll - scrollSpeed,
    //            behavior: 'smooth'
    //        });

    //        // Check if reached the top, then switch direction after delay
    //        if (currentScroll - scrollSpeed <= 0) {
    //            setTimeout(() => {
    //                scrollingUp = false;
    //            }, delayTime);
    //        }
    //    } else {
    //        scrollingDiv.scrollTo({
    //            top: currentScroll + scrollSpeed,
    //            behavior: 'smooth'
    //        });

    //        // Check if reached the bottom, then switch direction after delay
    //        if (currentScroll + scrollingDiv.clientHeight >= scrollingDiv.scrollHeight) {
    //            setTimeout(() => {
    //                scrollingUp = true;
    //            }, delayTime);
    //        }
    //    }
    //}

    //function autoScrollProcessing() {
    //    const scrollingDiv = document.getElementById('jobInfoProcessing');
    //    const currentScroll = scrollingDiv.scrollTop;

    //    if (scrollingUp) {
    //        scrollingDiv.scrollTo({
    //            top: currentScroll - scrollSpeed,
    //            behavior: 'smooth'
    //        });

    //        // Check if reached the top, then switch direction after delay
    //        if (currentScroll - scrollSpeed <= 0) {
    //            setTimeout(() => {
    //                scrollingUp = false;
    //            }, delayTime);
    //        }
    //    } else {
    //        scrollingDiv.scrollTo({
    //            top: currentScroll + scrollSpeed,
    //            behavior: 'smooth'
    //        });

    //        // Check if reached the bottom, then switch direction after delay
    //        if (currentScroll + scrollingDiv.clientHeight >= scrollingDiv.scrollHeight) {
    //            setTimeout(() => {
    //                scrollingUp = true;
    //            }, delayTime);
    //        }
    //    }
    //}

    //function autoScrollCompleted() {
    //    const scrollingDiv = document.getElementById('jobInfoCompleted');
    //    const currentScroll = scrollingDiv.scrollTop;

    //    if (scrollingUp) {
    //        scrollingDiv.scrollTo({
    //            top: currentScroll - scrollSpeed,
    //            behavior: 'smooth'
    //        });

    //        // Check if reached the top, then switch direction after delay
    //        if (currentScroll - scrollSpeed <= 0) {
    //            setTimeout(() => {
    //                scrollingUp = false;
    //            }, delayTime);
    //        }
    //    } else {
    //        scrollingDiv.scrollTo({
    //            top: currentScroll + scrollSpeed,
    //            behavior: 'smooth'
    //        });

    //        // Check if reached the bottom, then switch direction after delay
    //        if (currentScroll + scrollingDiv.clientHeight >= scrollingDiv.scrollHeight) {
    //            setTimeout(() => {
    //                scrollingUp = true;
    //            }, delayTime);
    //        }
    //    }
    //}

    //setInterval(autoScrollPending, 100); 
    //setInterval(autoScrollProcessing, 110); 
    //setInterval(autoScrollCompleted, 120); 

    return (
        <>
            <Header />
            <div className="Bg">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className="row contentMainMenu mx-1 mt-1" id="contentMainMenu">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                <div className="row">
                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2"></div>
                                    <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8 text-center pt-1">
                                        <div className="row">
                                            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 border-red text-black text-center">Urgent</div>
                                            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 bg-primary text-white text-center">Correction</div>
                                            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 bg__rejected text-white text-center">Rejected</div>
                                            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 bg__overOneDay text-white text-center">Over 1 Day</div>
                                            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 bg__overTwoDay text-white text-center">Over 2 Days</div>
                                            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 bg__overThreeDay text-white text-center">Over 3 Days</div>
                                        </div>
                                    </div>
                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                                        <p className="text-end fw-bolder mb-0 pb-0 text-danger">Section : {sectionName}</p>
                                    </div>
                                </div>
                                <div className="row mt-3 pt-0">
                                    <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 job__info" id="jobInfoPending">
                                        <h4 className="text-center header__sticky--count">Pending
                                            <sup className="count text-dark">
                                                <span className="me-1 ms-1">{pending.length}</span>
                                                /
                                                <span className="border-red px-1 py header--sticky0 ms-1 me-1">{pendingUrgentTot}</span>
                                                /
                                                <span className="bg__rejected text-white px-1 py-0 ms-1 me-1">{pendingRejectedTot}</span>
                                                /
                                                <span className="bg__overOneDay text-white px-1 py-0 ms-1 me-1">{pendingTotOverOneDay}</span>
                                                /
                                                <span className="bg__overTwoDay text-white px-1 py-0 ms-1 me-1">{pendingTotOverTwoDay}</span>
                                                /
                                                <span className="bg__overThreeDay text-white px-1 py-0 ms-1">{pendingTotOverThreeDay}</span>
                                            </sup>
                                        </h4>
                                        <table className="table table-borderless fw-bold">
                                            <thead className="thead--color--2 header__sticky--table">
                                                <tr className="small fw-bold">
                                                    <td >Job Date</td>
                                                    <td>In Date</td>
                                                    <td>Job #</td>
                                                    <td>Employee</td>
                                                    <td>Activity</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    pending.map((data, index) => (
                                                        <tr key={index} className={data.cssClass}>
                                                            <td>{common.convertToMonthDay(data.jobDate)}</td>
                                                            <td>{data.inTime.startsWith("1900") ? "" : common.convertToMonthDay(data.inTime)}</td>
                                                            {
                                                                data.isOut ?
                                                                    <td>{data.jobNo} <sup>Out</sup></td>
                                                                    :
                                                                    <td>{data.jobNo}</td>
                                                            }
                                                            <td>{data.empCallName}</td>
                                                            <td>{data.activity}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 job__info" id="jobInfoProcessing">
                                        <h4 className="text-center text-danger header__sticky--count">Processing
                                            <sup className="count text-dark">
                                                <span className="me-1 ms-1">{processing.length}</span>
                                                /
                                                <span className="border-red px-1 py-0 ms-1 me-1">{processingUrgentTot}</span>
                                                /
                                                <span className="bg-primary text-white px-1 py-0 ms-1 me-1">{processingCorrectionTot}</span>
                                                /
                                                <span className="bg__rejected text-white px-1 py-0 ms-1 me-1">{processingRejectedTot}</span>
                                                /
                                                <span className="bg__overOneDay text-white px-1 py-0 ms-1 me-1">{processingTotOverOneDay}</span>
                                                /
                                                <span className="bg__overTwoDay text-white px-1 py-0 ms-1 me-1">{processingTotOverTwoDay}</span>
                                                /
                                                <span className="bg__overThreeDay text-white px-1 py-0 ms-1">{processingTotOverThreeDay}</span>
                                            </sup>
                                        </h4>
                                        <table className="table table-borderless fw-bold">
                                            <thead className="thead--color--2 header__sticky--table">
                                                <tr>
                                                    <td>Job #</td>
                                                    <td>Employee</td>
                                                    <td>Activity</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    processing.map((data, index) => (
                                                        <tr key={index} className={data.cssClass}>
                                                            {
                                                                data.isOut ?
                                                                    <td>{data.jobNo} <sup>Out</sup></td>
                                                                    :
                                                                    <td>{data.jobNo}</td>
                                                            }
                                                            <td>{data.empCallName}</td>
                                                            <td>{data.activity}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 job__info" id="jobInfoCompleted">
                                        <h4 className="text-center text-success header__sticky--count">
                                            Completed Today
                                            <sup className="count text-dark">
                                                <span className="me-1 ms-1">{completed.length}</span>
                                                /
                                                <span className="border-red px-1 py-0 ms-1 me-1">{completedUrgentTot}</span>
                                                /
                                                <span className="bg__rejected text-white px-1 py-0 ms-1 me-1">{completedRejectedTot}</span>
                                            </sup>
                                        </h4>
                                        <table className="table table-borderless fw-bold">
                                            <thead className="thead--color--2 header__sticky--table">
                                                <tr>
                                                    <td>Job #</td>
                                                    <td>Employee</td>
                                                    <td>Activity</td>
                                                    <td>Time (24 Hr)</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    completed.map((data, index) => (
                                                        <tr key={index} className={data.cssClass}>
                                                            <td>{data.jobNo}</td>
                                                            <td>{data.empCallName}</td>
                                                            <td>{data.activity}</td>
                                                            <td>{common.convertToTime(data.completedDate)}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}