import { Routes,Route, Navigate } from 'react-router-dom';

import { mainMenuParentRoutePath } from './routePath';

import useToken from '../common/hooks/useToken';
import useUserName from '../common/hooks/useUserName';
import usePcode from '../common/hooks/usePcode';

import LogIn from '../pages/logIn';
import MainMenu from '../pages/mainMenu';

export default function App() {
    const { token, setToken } = useToken();
    const { setLogedUserName } = useUserName();
    const { setLoggedPCode } = usePcode();

    const logOut = () => {
        setToken('');
        setLogedUserName('');
        setLoggedPCode('');
    }

    //if (!token)
    //    return <LogIn setToken={setToken} setLogedUserName={setLogedUserName} setLoggedPcode={setLoggedPCode} />;

    return (
        <>
            <Routes>
                <Route path={'*'} element={<Navigate to={'/mainmenu'} />}></Route>
                <Route path={mainMenuParentRoutePath} element={<MainMenu logOut={logOut} />}></Route>
            </Routes>
        </>
    )
}